* {
  direction: ltr;
  box-sizing: border-box;
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
  z-index: 2;
  /* overflow: hidden; */
}
.luckiest-guy-regular {
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
}
.gameTimeContainer {
  position: absolute;
  top: 30px;
  left: 30px;
}
.app {
  background-image: url("https://cdn.pixabay.com/photo/2022/11/24/02/28/clouds-7613361_1280.png");
  height: 100vh;
}
.letterContainer {
  /* height: 50px;
  width: 50px; */
  font-size: 50px;
  margin: 10px;
  /* padding: 50px; */
}
.allLettersConainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.letterContainer {
  background: linear-gradient(
    45deg,
    #ff6b6b,
    #ff8e53,
    #ffab40,
    #ffd633,
    #ffea00
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  text-fill-color: transparent;
}

.itemContainer {
  margin-right: auto;
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 300px;
  height: auto;
  overflow: visible;
}
.itemImage {
  /* width: 300px; */
  width: -webkit-fill-available;
  overflow: visible;
}
.itemWord {
  font-size: 50px;
  overflow: visible;
}
.winner {
  margin: auto;
  font-size: 72px;
  text-align: center;
  width: 100%;
}
.mr-auto {
  margin-right: auto;
}
.mi-auto {
  margin-left: auto;
}
.timerContainer {
  position: absolute;
  top: 60px;
  left: 30px;
}
.pointsContainer {
  /* position: absolute; */
  /* top: 30px;
  right: 30px; */
  /* display: flex;
  flex-direction: column;
  width: fit-content; */
  font-size: 24px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  position: relative;
  align-items: flex-start;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotateY(45deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(405deg);
  }
}
.spinningCoin {
  width: 20px;
  height: 20px;
  position: relative;
  top: 13px;
  right: -7px;

  /* background-color: gold; */

  /* transform: translate(-50%, -50%); */
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

.rainbow {
  position: absolute;
  opacity: 0.3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.bonus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffbe0b;
  font-size: 50vh;
  font-family: "Calligraffitti", cursive;
  font-weight: 700;
  font-size: 13rem;
  position: absolute;
  text-shadow: -15px 5px 20px #ced0d3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.02em;
  text-align: center;
  color: #f9f1cc;
  text-shadow: 5px 5px 0px #ffb650, 10px 10px 0px #ffd662, 15px 15px 0px #ff80bf,
    20px 20px 0px #ef5097, 25px 25px 0px #6868ac, 30px 30px 0px #90b1e0;
  z-index: 10;
}
.displayNone {
  display: none;
}
.bonusImg {
  height: 30vh;
  margin-bottom: -60px;
}
/* Modal.css */
.modal-overlay {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 80%;
  z-index: 100000;
}
.modal .counterSymbol {
  height: 58px;
}
.modal h2 {
  text-align: center;
}
.topScoresContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.scoreImg {
  height: 20px;
}
.golden-border {
  border: 4px solid #ffd700; /* Golden color */
  border-radius: 10px;
  background-color: #fff; /* Background color */
  box-shadow: -6px 8px 11px;
}
.ScoreContainer {
  position: relative;
  margin: 10px;
  min-height: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}
.golden-border .avatar {
  margin-top: -16px;
  height: 48px;
  margin-bottom: 23px;
}
.scoreItem {
  margin: 20px auto 20px auto;
  overflow: visible;
}

.golden-border:before,
.golden-border:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px; /* Thickness of the 3D border */
  background: linear-gradient(
    to right,
    transparent,
    #ffd700,
    transparent
  ); /* Gradient for the golden color */
  top: -4px;
  left: 0;
  border-radius: 10px;
}

.golden-border:after {
  bottom: -4px;
  top: auto;
}
.galleryOuter {
  width: fit-content;
  max-width: 100%;
  margin: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}
.galleryInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  position: relative;
  overflow: scroll;
  z-index: 1;
}
.galleryInner img {
  height: 200px;
  margin: 10px;
  border-radius: 5px;
  z-index: 1;
  width: auto;
}
body {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Adjust color if needed */
}

/* For Webkit browsers like Chrome and Safari */
body::-webkit-scrollbar {
  width: 6px; /* Adjust width if needed */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* Adjust color if needed */
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.left-arrow {
  left: 10px;
  z-index: 2;
}

.right-arrow {
  right: 10px;
  z-index: 2;
}
.chooseAvatar {
  transition: transform 0.2s; /* Use 'transition' instead of 'translate' */
  overflow: visible;
}
.chooseAvatar:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.mainAvatar {
  /* position: absolute;
  top: 50%; */
  left: 0%;
  height: 100px;
}
.progress-bar-container {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
}

.progress-bar {
  height: 5px;
  line-height: 5px;
  text-align: center;
  color: #fff;
  background-color: #f2ff00;
  transition: width 1s;
}
@keyframes blinking {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.avatarCard {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 300px;
  padding-top: 30px;

  /* align-items: end; */
}
.mainContainer {
  display: flex;
  flex-direction: row;
  max-width: 600px;
  margin: auto;
  overflow: visible;
}
.centerContainer {
  width: 600px;
  max-width: 80%;
  margin: auto;
}
.WordComponentContainer {
  width: 50%;
  max-height: 300px;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  /* flex-direction: column; */
  overflow: visible;
}
.WordComponentContainer img {
  max-width: 80%;
  max-height: 80%;
}

.gameContainer img {
  /* width: 150px; */
  /* max-width: 45%; */
  max-height: 150px;
  height: 60%;
  margin: 10px;
}
.gameContainer {
  overflow: visible;
}
.gameContainer:hover {
  z-index: 10;
}
.clickable {
  /* max-width: 100%; */
  transition: transform 0.2s;
}
.clickable:hover {
  cursor: pointer;
  transform: translateX(5%) scale(1.1); /* Adjust the -20% as needed */
  transform-origin: center;
  z-index: 10;
  overflow: visible;
}
/* Balloon.css */
/* Balloon CSS */
.balloon {
  margin: 10px auto;
  position: relative;
  transform-origin: 50% 300%;
  width: 16rem;
  animation: anim 5s ease-in-out 1s infinite alternate;
  overflow: visible;
}

.ball {
  height: 10rem;
  width: 50%;
  background: rgba(0, 50, 150, 0.8);
  border-radius: 80%;
  position: relative;
  overflow: hidden;

  border: 1px solid #555;
  box-shadow: 2px 2px 5ox #aaa;
}

.ball:before {
  content: "";
  height: 20rem;
  width: 16rem;
  background: rgba(0, 50, 150, 0.3);
  border-radius: 80%;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: -1rem;
}

.stick {
  content: "";
  width: 0.1rem;
  height: 100%;
  background: rgba(50, 50, 50, 0.8);
  display: inline-block;
  position: absolute;
  top: 10rem;
  left: 4rem;
  overflow: visible;
}
.counterContainer {
  position: absolute;
  height: 71px;
  font-size: 53px;
  display: flex;
  width: 100px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  overflow: visible;
}
.counterSymbol {
  height: 100%;
}
.counterDigits {
  overflow: visible;
}
button {
  background-color: #ff5900;
  color: white;
  margin-top: 10px;
  height: 30px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s;
  box-shadow: -7px 10px 18px 3px rgba(0, 0, 0, 0.8);
  text-shadow: 0 19px -10px;
}
button:hover {
  background-color: #ff3300;
  cursor: pointer;
}
.gamesContainer {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}
.doors {
  display: flex;
}

.door {
  background: #fafafa;
  width: 100px;
  /* height: 110px; */
  overflow: hidden;
  border-radius: 5px;
  margin: 2px;
}

.boxes {
  transition: transform 1s ease-in-out;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}
.slotMachineContainer > img {
  height: 543px !important;
  /* width: 361px; */
  position: absolute;
}

/* .buttons {
  margin: 1rem 0 2rem 0;
}

button {
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 0.2rem;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
} */

.info {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.slotMachine {
  display: flex;
  justify-content: center;
}
.slotMachineContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: auto;
  /* background-image: url("../public/background1.jpeg"); */
  background-image: url("https://th.bing.com/th/id/OIG2.tE8Z3Qv8phIfwB3cK.YT?w=1024&h=1024&rs=1&pid=ImgDetMain");
}
.slotMachineContainer button {
  /* margin: auto; */
  margin-top: 118px;
  bottom: -116px;
  font-size: 47px;
  width: 216px;
  height: 74px;
  position: absolute;
  left: 50% !important;
}
.galleryInner img {
  height: 100px;
}
.centered-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slotMachine.centered-item {
  top: 10% !important;
  /* width: 185px !important; */
  width: 270px;
  /* height: 98px !important; */
  height: 133px;
  left: 50% !important;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.verticalLogo {
  width: 120px;
  margin: 20px;
}
.SlotGamesManuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.creatureManuContainer {
  height: 100px;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  overflow: visible;
  padding: 30px;
  height: 130px;
}

.CreatureContainer {
  overflow: visible;
  height: 100px;
}
.creatureimg {
  height: 100px;
  overflow: visible;
}
/* .CreatureContainer .galleryInner,
img {
  height: auto;
} */
.hiddenCreature {
  filter: grayscale(100%) brightness(0%);
  width: 100%;
  height: auto;
}
.hiddenCreatureContainer {
  position: relative;
  height: 126px;
  margin: 19px;
}
.payedFor {
  position: absolute;
}
.rungame-container {
  position: relative;
  width: 100%;
  height: 687px;
  border: 1px solid #000;
  margin: 0 auto;
  overflow: hidden;
  background-image: url(https://th.bing.com/th/id/OIG1..AbRJQUr7Hv6QQxyWjFj?w=1024&h=1024&rs=1&pid=ImgDetMain);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.rungame-creature::before {
  filter: invert(100%);
}
.rungame-creature {
  position: absolute;
  bottom: 0;
  left: 20%;

  width: 120px;
  height: 120px;
  /* background-color: green; */
}

.rungame-obstacle {
  position: absolute;
  bottom: 0;
  width: 120px;
  height: 120px;
  /* border-radius: 50%; */
  background: #ffff00cc;
  box-shadow: 0 0 77px 35px yellow;
  /* transform: translateX(-50%); /* Add yellow aura effect */
}

.rungame-game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: red;
}

.rungame-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.runner {
  position: absolute;
  bottom: -6px;
  left: 420px;
  height: 129px;
}
.descendmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  overflow: hidden;
  z-index: 9999;
  transition: opacity 3s ease;
}

.descendcurtain {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff; /* White background */
  transition: top 3s ease;
}

.descendopen {
  opacity: 1;
}

.descendclosed {
  opacity: 0;
}

.descendopen .descendcurtain {
  top: 0;
}
.water-effect {
  width: 200px; /* Adjust width as needed */
  height: 4px; /* Adjust height as needed */
  position: relative;
  background: linear-gradient(
    to right,
    #ff000096,
    #a70101e8 50%,
    transparent 50%
  );
  background-size: 200% 100%;
  animation: water 2s infinite linear;
  /* background-position: 100% 50%; */
  background-position: 25 50%;
}

/* Animation for the water effect */
/* @keyframes water {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
} */

/* Optional: Add some styling to enhance the appearance */
.water-effect:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  border-radius: 50%;
}

.water-effect:after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  border-radius: 50%;
}
.boughtCratureContainer {
  display: flex;
}
.boughtCreatureBars {
  width: 50px;
}
.boughtCreatureBars progress {
  width: 100%;
}
.boughtCreatureBars p {
  margin: 0px;
}
.slotMachineContainer .galleryInner {
  height: 147px;
}
.countersContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 270px;
}
.countersContainer.centered-item {
  border-radius: 10px;
  top: 103px;
  background: #ffffffa3;
  border-color: darkgray;
  border-width: 0.1px;
  border-style: outset;
}
@media only screen and (max-width: 767px) {
  /* Styles specific to phones */
  /* For example: */
  .letterContainer {
    font-size: 20px;
  }
  .itemWord {
    font-size: 20px;
  }
  .topScoresContainer {
    padding: 0px;
  }
  .scoreItem {
    font-size: 16px;
  }
}
